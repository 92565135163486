import { ApiKey } from '@/enums';
import { useMutation, useQuery } from 'react-query';
import { CeskaSporitelnaApi } from '../base';
import { params } from '../utils';
import { BatchProcessParams } from '@/components/Module/TableVerifiedConnect';
import { LoadingContext } from '@/components/Loading/types';
import { ApiListOptions } from '@/components';

const api = new CeskaSporitelnaApi();

export const useApiCeskaSporitelnaVerifyConnection = () => {
  return useQuery([ApiKey.VerifyConnectionGet], async () => {
    let response = await api.apiV1AdminCeskaSporitelnaVerifyConnectionGet(params({}));

    if (!response?.data?.data?.isConnected) {
      const url = new URL(window.location.href);
      const authCode = url.searchParams.get('code');
      if (authCode) {
        await api.apiV1AdminCeskaSporitelnaCompleteAuthenticationPost(params({ code: authCode }));
        response = await api.apiV1AdminCeskaSporitelnaVerifyConnectionGet(params({}));
      }
    }
    return response.data.data;
  });
};

export const useApiCeskaSporitelnaVerifiedConnectionConnect = () => {
  return useMutation(async () => {
    const response = await api.apiV1AdminCeskaSporitelnaAuthenticationUrlGet(params({}));
    if (response?.data?.data?.url) {
      window.location.replace(response.data.data.url);
    }
  });
};

export const useApiCeskaSporitelnaVerifiedConnectionBatchProcess = () => {
  return useMutation(async (options: BatchProcessParams) => {
    options.loadingContext.showLoading();
    const response = await api.apiV1AdminCeskaSporitelnaBatchPost(params({}));
    options.loadingContext.hideLoading();
    if (response?.data?.data?.urls) {
      options.setAuthorizeUrls(response.data.data.urls);
    }
  });
};

export const useApiCeskaSporitelnaVerifiedConnectionDirectDebit = () => {
  return useMutation(async (options: LoadingContext) => {
    options.showLoading();
    await api.apiV1AdminCeskaSporitelnaDirectDebitPost(params({}));
    options.hideLoading();
  });
};

export const useApiCeskaSporitelnaPollBatches = () => {
  return useMutation(async (options: LoadingContext) => {
    options.showLoading();
    // disabled until fixed
    // await api.apiV1AdminCeskaSporitelnaPollBatchesPost(params({}));
    options.hideLoading();
  });
};

export const useApiCeskaSportelnaCzAuthorizationstList = (options: ApiListOptions) => {
  return useQuery([ApiKey.BankAuthorizations, options], async () => {
    const response = await api.apiV1AdminCeskaSporitelnaPaymentBatchesGet(params(options));

    return response.data;
  });
};

export const useApiCeskaSporitelnaBatchAuthorizationUrl = () => {
  return useMutation(async (batchId: number) => {
    const response = await api.apiV1AdminCeskaSporitelnaAuthorizationLinkBatchIdPost(
      params({ batchId }),
    );

    return response.data;
  });
};
