// @ts-nocheck
// tslint:disable
import { AxiosPromise } from "axios";
import { APIHandler, APIURLTypes, APIParams } from "../base";
import { ApiErrorResponse, ApiResponse, CardDtoListApiDataResponse, CardStatus, KycModel } from "../model";

export class CardsApi extends APIHandler {
    static urls: APIURLTypes.CardsApi = {
        apiV1AdminCardsGet: "/api/v1/admin/cards",
        apiV1ClientCardsPost: "/api/v1/client/cards"
    };
    
    constructor() {
        super("CardsApi");
    }
    
    /**
     * @param cardIdentifier Card identifier assigned by card provider
     * @param mCID Card identifier within card scheme
     * @param pAN Card number (masked)
     * @param status Payment state
     * @param companyId Company identifier
     * @param workerName Worker name
     * @param sortBy Sort by column name
     * @param isSortAscending Is sort ascending
     * @param offset Items offset
     * @param limit Page Size
     */
    public apiV1AdminCardsGet(params: APIParams & {
        cardIdentifier?: string;
        mCID?: string;
        pAN?: string;
        status?: CardStatus;
        companyId?: number;
        workerName?: string;
        sortBy?: string;
        isSortAscending?: boolean;
        offset?: number;
        limit?: number;
    } = {}): AxiosPromise<CardDtoListApiDataResponse> {
        const apiURL: string = this.initAPIURL(CardsApi.urls.apiV1AdminCardsGet, null);
        params.options = this.initOptions(params.options, { cardIdentifier: params.cardIdentifier, mCID: params.mCID, pAN: params.pAN, status: params.status, companyId: params.companyId, workerName: params.workerName, sortBy: params.sortBy, isSortAscending: params.isSortAscending, offset: params.offset, limit: params.limit }, {  }, params.canceler);
        const body: any = null;
        return this.makeCall<CardDtoListApiDataResponse>("GET".toLowerCase(), apiURL, "apiV1AdminCardsGet", params.options, body, "apiV1AdminCardsGet");
    }

    /**
     * Creates a card for worker in the payment card system
     * @param kycModel 
     */
    public apiV1ClientCardsPost(params: APIParams & {
        kycModel?: KycModel;
    } = {}): AxiosPromise<ApiResponse> {
        const apiURL: string = this.initAPIURL(CardsApi.urls.apiV1ClientCardsPost, null);
        params.options = this.initOptions(params.options, {  }, {  }, params.canceler);
        const body: any = params.kycModel;
        return this.makeCall<ApiResponse>("POST".toLowerCase(), apiURL, "apiV1ClientCardsPost", params.options, body, "apiV1ClientCardsPost");
    }

}
