// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ExternalPaymentState } from './external-payment-state';

// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class PaymentBatch extends APIModel {
    id: number;
    authorizationState: ExternalPaymentState;
    paymentsCount: number;
    totalAmount: number;

    constructor(options?: APIModelOptions) {
        super(PaymentBatch.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "authorizationState", type: "ExternalPaymentState" },
            { name: "paymentsCount", type: "number" },
            { name: "totalAmount", type: "number" }
        ];
    }
}




